import React from "react"
import { Helmet } from "react-helmet"
import "../style/style.scss"
import RadhakrishnanK from "../img/radhakrishnan.svg"
import favicon from "../img/favicon.png"
const IndexPage = () => {
  return (
    <div>
      <Helmet>
        <title>RADHAKRISHNAN K - Brand Identity Designer</title>
        <meta
          name="description"
          content="RADHAKRISHNAN K - Brand Identity Designer | Your Brand one step ahead"
        />
        <link rel="icon" href={favicon} type="img/x-icon"></link>
      </Helmet>
      <div className="container fullHeight">
        <div className="columns">
          <div className="text-center content">
            <img src={RadhakrishnanK} alt="RADHAKRISHNAN K"></img>
            <h1>We've something special for you.</h1>
            <h3>
              We can't wait for you to see it.
              <br /> Please check back soon
            </h3>
            <h3>
              <b>Get in Touch</b>
              <br />
              +91 9003 32 2323 <br></br> hi@radhakrishnan.in
            </h3>
          </div>
        </div>
      </div>
    </div>
  )
}
export default IndexPage
